<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <transition name="fade" mode="in-out">
    <component :is="layout" />
  </transition>
</template>
<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, shallowRef, defineAsyncComponent, onMounted } from 'vue'
import { useMeta, useActiveMeta } from 'vue-meta'
// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/, 'sync')
const componentNames = requireContext.keys().map((file) => file.replace(/(^.\/)|(\.vue$)/g, ''))
const components = {}
componentNames.forEach((component) => { // component represents the component name
  components[component] = defineAsyncComponent(() => // import each component dynamically
    import('@/layouts/' + component + '.vue')
  )
})
export default {
  components,
  setup () {
    useMeta({
      title: 'Konfigurator'
    })
    const store = useStore()
    const metaInfo = useActiveMeta()
    const router = useRouter()
    const layout = shallowRef(null)
    const defaultLayout = ref('default')
    router.beforeEach(beforeEach)
    function beforeEach (to, from, next) {
      const l = to.meta.layout
      if (typeof l === 'undefined') {
        layout.value = defaultLayout.value
      }
      layout.value = components[l]
      return next()
    }
    function initStat () {
      const url = document.referrer
      store.dispatch('stats/setReferrerUrl', url)
    }

    onMounted(() => {
      initStat()
    })
    return {
      layout,
      metaInfo
    }
  }
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
